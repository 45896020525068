<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }

  .custom-file {
    width: 50%;
  }

  .btn.dropdown-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .red-font {
    cursor: pointer;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ExamHeader from "./exam-header";
  import {
    editExamRecord,
    getExamDetails,
    getMould,
  } from "@/api/admin/exam/examRecord.js"
  import {
    creatKscjdak,
    creatKswjk,
    creatKshmdk,
    creatKszwk
  } from "@/api/admin/exam/createExamku.js"
  import {
    creatKszymdk
  } from "@/api/admin/exam/zymdk.js"
  import {
    examFree,
  } from "@/api/admin/base/examFree.js"
  /**
   * 考试报名--模板

   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ExamHeader,

    },
    data() {
      return {
        title: "考试报名信息管理/",
        title2: "",
        ksmc: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "关联设置",
            active: true
          }
        ],
        sid: "",
        flbm: "",
        ksbmbh: "",
        checked1: true,
        module: "",
        fileId: "",
        excelFields: [],
        printMould: [],
        scoreCard: [],
        msList: [],
        examForm: {
          cjdakbm: "",
          sfqycjdak: "",
          wjkbm: "",
          sfqywjk: "",
          mssqmbid: "",
          mssqmbmc: "",
          sfqymssqmb: "",
          cjdysid: "",
          cjdysmc: "",
          sfqycjdys: false,
          zkzysid: "",
          zkzysmc: "",
          sfqyzkzys: false
        },
        tsmdkbm: "register_user_special",
        tsmdkwj: ""
      };
    },
    methods: {
      //上传库
      uploadFile(name, title, module) {
        var _this = this;
        let func;
        if (!name) {
          if (module == "CJDAK") {
            func = creatKscjdak
          }
          if (module == 'WJK') {
            func = creatKswjk
          }
          if (module == 'HMDK') {
            func = creatKshmdk
          }
          if (module == 'ZWK') {
            func = creatKszwk
          }
          if (module == 'KSZYMDK') {
            func = creatKszymdk
          }
          func({
            ksbmbh: _this.ksbmbh
          })
        }
        _this.$importModal().show({
          title: title,
          module: module,
          ksbmbh: _this.ksbmbh,
          success: _this.$forceUpdate()
        })

      },


      editExamForm() {
        let formDate = this.examForm
        editExamRecord({
          ...formDate,
          sid: this.sid
        }).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.$refs.header.getUrl('/admin/examFees')
      },
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.flbm = this.examForm.flbm
            this.ksmc = this.examForm.ksmc
            if (this.examForm.cjdysid) {
              this.examForm.sfqycjdys = true
            } else {
              this.examForm.sfqycjdys = false
            }
            if (this.examForm.zkzysid) {
              this.examForm.sfqyzkzys = true
            } else {
              this.examForm.sfqyzkzys = false
            }
            // examFree({
            //   flbm: this.flbm
            // }).then(res => {
            //   if (res.status) {
            //     this.msList = res.data
            //   }
            // })
          }
        })
      },

      // 准考证
      getPrintMould() {
        getMould({
          mblx: "准考证"
        }).then(res => {
          if (res.status) {
            this.printMould = res.data
          }
        })
      },
      getScoreCardCard() {
        getMould({
          mblx: "成绩单"
        }).then(res => {
          if (res.status) {
            this.scoreCard = res.data
          }
        })
      },
      chooseCjd() {
        let val = this.examForm.cjdysmc
        let obj = this.scoreCard.find(k => {
          return k.mbmc === val
        })
        this.examForm.cjdysid = obj.mid

      },
      editForm(str) {
        let formDate
        if (str == 'cjd') {
          formDate = {
            sfqycjdys: this.examForm.sfqycjdys,
            cjdysid: this.examForm.cjdysid,
            cjdysmc: this.examForm.cjdysmc

          }
        }
        if (str == 'zkz') {
          formDate = {
            sfqyzkzys: this.examForm.sfqyzkzys,
            zkzysmc: this.examForm.zkzysmc,
            zkzysid: this.examForm.zkzysid
          }

        }
        let sid = this.sid
        editExamRecord({
          ...formDate,
          sid
        }).then(res => {
          if (res.status) {
            if (str == 'zkz') {
              this.examForm.sfqyzkzys = true
            } else if (str == 'cjd') {
              this.examForm.sfqycjdys = true
            }
          }
        })

      },
      // 下载模板
      downloadModule(module) {
        let url
        if (module == "KSZYMDK" || module == "TSMDK") {
          url = this.API_URL + "/yethan/register/downloadTemplate/" + module + "?ksbmbh=" + this.ksbmbh + "&ytoken=" +
            this.getStore(
              'accessToken')
        } else {
          url = this.API_URL + "/yethan/register/downloadTemplate/" + module + "?ytoken=" + this.getStore(
            'accessToken')
        }
        window.location.href = url

      },
      chooseDm() {
        let val = this.examForm.zkzysmc
        let obj = this.printMould.find(k => {
          return k.mbmc === val
        })
        this.examForm.zkzysid = obj.mid

      },
    },
    mounted() {
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
        this.getPrintMould()
        this.getScoreCardCard()
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :items="items" />
    <ExamHeader :ksbmbh="ksbmbh" ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <form action="" method="" ref="examForm">

              <div class="table-responsive border">
                <table class="table  light-table table-hover table-bordered ">
                  <thead class="thead-light">
                    <tr>
                      <th>选项</th>
                      <th>是否启用</th>
                      <th>已关联信息</th>
                      <th>上传数据</th>
                      <th>说明 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>成绩档案库
                      </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqycjdak" :disabled="!examForm.cjdakbm" switch
                          class=" switch-check" style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>
                        <div class="flexList">{{examForm.cjdakbm}}
                          <a :href="`/admin/enrollArchives?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`" target="_blank"
                            class="blue-font ml-2" v-if="examForm.cjdakbm">查看数据</a>
                          <div v-else>
                            暂无数据，请先上传文件
                          </div>
                          <b-form-checkbox v-model="examForm.sfxstgsj" switch class=" switch-check ml-2"
                            style="line-height: 22px;">
                            是否显示通过时间
                          </b-form-checkbox>

                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="examForm.cjdakbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(examForm.cjdakbm,'考试档案库导入','CJDAK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>


                      </td>
                      <td>
                        <div class="red-font" @click="downloadModule('CJDAK')">只支持dbf，点击下载模板</div>
                        <!-- <a :href="API_URL+'/yethan/register/cjdak/downloadTemplate?ytoken=' + getStore('accessToken')"
                          target="_blank">
                          <div class="red-font">只支持dbf，点击下载模板</div>
                        </a> -->
                      </td>


                    </tr>
                    <tr>
                      <td>违纪库 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqywjk" :disabled="!examForm.wjkbm" switch
                          class=" switch-check" style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>{{examForm.wjkbm}} <a :href="`/admin/enrollBreach?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`"
                          target="_blank" class="blue-font ml-2" v-if="examForm.wjkbm">查看数据</a>
                        <div v-else>
                          暂无数据，请先上传文件
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="examForm.wjkbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(examForm.wjkbm,'考试违纪库导入','WJK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>

                      </td>
                      <td>
                        <div class="red-font" @click="downloadModule('WJK')">只支持dbf，点击下载模板</div>
                      </td>

                    </tr>
                    <tr>
                      <td>黑名单库 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqyhmdk" :disabled="!examForm.hmdkbm" switch
                          class=" switch-check" style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>{{examForm.hmdkbm}} <a :href="`/admin/enrollHmdk?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`"
                          target="_blank" class="blue-font ml-2" v-if="examForm.hmdkbm">查看数据</a>
                        <div v-else>
                          暂无数据，请先上传文件
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="examForm.hmdkbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(examForm.hmdkbm,'考试黑名单库导入','HMDK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>
                      </td>
                      <td>

                        <div class="red-font" @click="downloadModule('HMDK')">只支持dbf，点击下载模板</div>
                      </td>

                    </tr>
                    <tr>
                      <td>考试职位库 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqyzwk" :disabled="!examForm.zwkbm" switch
                          class=" switch-check" style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>{{examForm.zwkbm}} <a :href="`/admin/enrollZwk?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`"
                          target="_blank" class="blue-font ml-2" v-if="examForm.zwkbm">查看数据</a>
                        <div v-else>
                          暂无数据，请先上传文件
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="examForm.zwkbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(examForm.zwkbm,'考试职位库导入','ZWK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>
                      </td>
                      <td>
                        <div class="red-font" @click="downloadModule('ZWK')">只支持dbf，点击下载模板</div>
                      </td>

                    </tr>
                    <tr>
                      <td>特殊名单库 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqytsmdk" switch class=" switch-check"
                          style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>{{tsmdkbm}} <a :href="`/admin/enrollTsmk?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`"
                          target="_blank" class="blue-font ml-2" v-if="tsmdkbm">查看数据</a>
                        <div v-else>
                          暂无数据，请先上传文件
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="tsmdkbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(tsmdkbm,'考试特殊名单库导入','TSMDK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>
                      </td>
                      <td>
                        <div class="red-font" @click="downloadModule('TSMDK')">只支持dbf，点击下载模板</div>
                      </td>

                    </tr>
                    <tr>
                      <td>专有名单库 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqyzymdk " :disabled="!examForm.zymdkbm" switch
                          class=" switch-check" style="cursor: no-drop;">
                        </b-form-checkbox>
                      </td>
                      <td>{{examForm.zymdkbm}} <a :href="`/admin/enrollZymdk?ksbmbh=${this.ksbmbh}&ksmc=${this.ksmc}`"
                          target="_blank" class="blue-font ml-2" v-if="examForm.zymdkbm">查看数据</a>
                        <div v-else>
                          暂无数据，请先上传文件
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-40  mr-2"
                          v-model="examForm.zymdkbm" />
                        <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                          @click="uploadFile(examForm.zymdkbm,'考试专有名单库导入','KSZYMDK')">
                          <i class="iconfont mr-1 icon-shangchuan"></i>上传文件
                        </el-button>
                      </td>
                      <td>
                        <div class="red-font" @click="downloadModule('KSZYMDK')">只支持dbf，点击下载模板</div>
                      </td>

                    </tr>
                    <tr>
                      <td>成绩单样式 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqycjdys" switch class=" switch-check">
                        </b-form-checkbox>
                      </td>
                      <td>
                        <div v-if="examForm.cjdysid">
                          成绩单样式已设置<a href="" class="blue-font ml-2">查看样表</a>
                        </div>
                        <div v-else>
                          请选择成绩单样式
                        </div>
                      </td>
                      <td class="flexList pl-2">
                        <select name="" class="form-control w-40  form-select mr-2" v-model="examForm.cjdysmc"
                          @change="chooseCjd">
                          <option value="" disabled>请选择样式</option>
                          <option :value="item.mbmc" v-for="(item,index) in scoreCard" :key="index" :label="item.mbmc">
                            {{item.mbmc}}
                          </option>
                        </select>

                        <div class="table-btn "><button type="button" class="btn btn-outline-info h30 w-md"
                            @click="editForm('cjd')">设置</button>
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>准考证打印样式 </td>
                      <td>
                        <b-form-checkbox v-model="examForm.sfqyzkzys" switch class=" switch-check">
                        </b-form-checkbox>
                      </td>
                      <td>
                        <div v-if="examForm.cjdysid">
                          准考证样式已设置<a href="" class="blue-font ml-2">查看样表</a>
                        </div>
                        <div v-else>
                          请选择准考证样式
                        </div>
                      <td class="flexList pl-2">
                        <select name="" class="form-control w-40  form-select mr-2" v-model="examForm.zkzysmc"
                          @change="chooseDm()">
                          <option value="" disabled>请选择样式</option>
                          <option :value="item.mbmc" v-for="(item,index) in printMould" :key="index" :label="item.mbmc">
                            {{item.mbmc}}
                          </option>
                        </select>
                        <div class="table-btn"><button type="button" class="btn btn-outline-info h30 w-md"
                            @click="editForm('zkz')">设置</button>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td> </td>
                      <td colspan="3" class="text-left pl-2" style="height: 50px;">
                        <button type="button" class="btn btn-info h30 sub-btn"
                          @click="editExamForm()">保存信息，进入下一步</button>
                        <!-- <a href="/admin/examFees"></a> -->
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 弹窗结束 -->
  </Layout>
</template>
