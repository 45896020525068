<style type="text/css" scoped>
  .score-head-bottom{
    cursor: pointer;
  }
</style>
<script>
  export default {
    props:["ksbmbh","sid"],
    data() {
      return {
        isActive: 1,
        pathArr: [{
            index: 1,
            url: "/admin/examEnroll",
            icon: "icon-riLine-edit-line",
            name: "考试基本信息",
          },
          {
            index: 2,
            url: "/admin/examEnrollMould",
            icon: "icon-scroll-paper",
            name: "报名表模板设计",
          },
          {
            index: 3,
            url: "/admin/examEnrollProgress",
            icon: "icon-waterfalls-h",
            name: "考试审核流程设计",
          },
          {
            index: 4,
            url: "/admin/examTime",
            icon: "icon-stLine-calendar-l",
            name: "考试时间设置",
          },
          {
            index: 5,
            url: "/admin/examBaseSet",
            icon: "icon-data",
            name: "考试关联库设置",
          },
          {
            index: 6,
            url: "/admin/examFees",
            icon: "icon-financing-one",
            name: "收费信息设置",
          },

        ],
      }
    },
    methods:{
      getUrl(url){
        // obj = {sid:sid}
        let query = this.$route.query
        let sid = query.sid
        let ksbmbh = query.ksbmbh 
        if(ksbmbh){
          this.$router.push({path:url,query:{sid:sid,ksbmbh:ksbmbh}})
        }
       else{
         this.$message({
           type: "warning",
           message: "请先添加考试",
         });
       }
      }
    },
    mounted() {
      this.isActive = window.location.pathname

    },
    created() {
      
      
    }

  };
</script>

<style scoped>

</style>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="flexList ">
            <div class="d-flex score-header" v-for="obj in pathArr" :key="obj.index">
              <div class="score-tips" :class="{active:isActive==obj.url}">
                <div class="score-head-top flexList justify-content-center">
                  <i :class="`${obj.icon} mr-2 iconfont `"></i>
                  <div class="fw600">步骤{{obj.index}}</div>
                </div>
                <!-- <div class="score-head-bottom">{{obj.name}} </div> -->
                <a @click="getUrl(obj.url,{sid})">
                  <div class="score-head-bottom">{{obj.name}} </div>
                </a>
              </div>
              <div class="score-head-right">
                <i class=" mr-2 iconfont icon-angle-double-right"></i>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>


  </div>

</template>
