import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest
} from "@/libs/axios";
// 免试库
export function getExamFree(params={}){
  return getRequest("/yethan/register/examFree/listPage", params);
}
// 删除
export function deleteExamFree(sid) {
  return deleteRequest('/yethan/register/examFree/' + sid);
}
// 添加
export function addExamFree(data = {}) {
  return postRequest('/yethan/register/examFree', data);
}
// 编辑
export function editExamFree(data = {}) {
  return putRequest('/yethan/register/examFree', data);
}

// 免试库简单列表
export const examFree = params =>{
  return getRequest("/yethan/register/examFree/easyList", params);
}

// 详情
export function getExamFreeDetail(msdm){
  return getRequest(`/yethan/register/examFree/getInfo?msdm=`+msdm);
}
